import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Common_get_api } from "../../api/get_api";
import EmtyList from "../../components/EmtyList/EmtyList";
import ListFilter from "../../components/ListFilter/ListFilter";
import MyTeamplCard from "../../components/MyTeamplCard/MyTeamplCard";
import { authinterface } from "../../interface/interface";
import { RootState } from "../../reducers";
import "./css/index.css";
import UseGetCommon from "../../hooks/UseGetCommon";
import Infinite from "../../components/Infinite/Infinite";
type Props = {};

const MyTeampl = (props: Props) => {
  const navigate = useNavigate();
  const { usr_id, usr_typ } = useSelector<RootState, authinterface>(
    (state) => state.auth
  );
  const [getData, PagingUpdate] = UseGetCommon({
    path:
      usr_typ === "READ"
        ? `/web/cstm/prjt?prjt_type_cd=prjt&leadr_usr_id=${usr_id}`
        : `/web/my/prjt?apprv_sts_cd=apprv_sts_select&prjt_type_cd=prjt&row_limit_cnt=1000`,
    limit: 0,
  });
  const [filter, setFilter] = useState<number | undefined>(undefined);
  return (
    <main className="my-teampl-page">
      <div className="wrapper">
        <div className="top-section">
          <div className="title">MY 팀플</div>
          <ListFilter
            list={filterLayout}
            now={filter !== undefined ? filterLayout[filter].title : "상태별"}
            __update={setFilter}
            type="teampl"
          />
        </div>
        {getData.length > 0 ? (
          <div className="my-teampl-list-section">
            {getData.map((item, idx) => {
              return (
                <MyTeamplCard
                  key={idx}
                  data={item}
                  usr_typ={usr_typ}
                  navigate={navigate}
                />
              );
            })}
            <Infinite PagingUpdate={PagingUpdate} />
          </div>
        ) : (
          <EmtyList text={`참여중인 팀플 목록이 없습니다`} />
        )}
      </div>
    </main>
  );
};

export default MyTeampl;

const filterLayout = [
  {
    title: "전체",
    type: "all",
  },
  {
    title: "결제필요",
    type: "payment",
  },
  {
    title: "진행중",
    type: "",
  },
  {
    title: "마감",
    type: "",
  },
];
