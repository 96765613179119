import React, { useCallback, useEffect, useState } from "react";
import { Common_get_api } from "../api/get_api";
import { PgInter } from "../interface/interface";

type Props = {
  path: string;
  limit?: number;
};

type ReturnType = [any[], () => void];

const UseGetCommon = ({ path, limit }: Props): ReturnType => {
  const [getData, setGetData] = useState<any[]>(
    Array(limit !== undefined ? limit : 4).fill(undefined)
  );
  const [pg, setpg] = useState<PgInter | null>(null);
  const PagingUpdate = useCallback(() => {
    if (pg) {
      const { page_last, page_next, page_cur } = pg;
      if (page_cur < page_last) {
        Common_get_api(path + `&page=${page_next}`).then(({ state, data }) => {
          if (state) {
            let clone = getData.slice();
            if (data.lst) {
              clone = [...clone, ...data.lst];
              if (Array.isArray(data.pg)) {
                setpg(data.pg[0]);
              }else {
     setpg(data.pg);
              }
            } else {
              clone = [...clone, ...data];
            }
            setGetData(clone);
          }
        });
      }
    }
  }, [pg, path, getData]);

  const GetCommonData = useCallback(() => {
    Common_get_api(path).then(({ state, data }) => {
      if (state) {
        if (data.lst) {
          setGetData(data.lst);
                  if (Array.isArray(data.pg)) {
                setpg(data.pg[0]);
              }else {
     setpg(data.pg);
              }
        } else {
          setGetData(data);
        }
      }
    });
  }, [path]);

  useEffect(() => {
    if (path.length > 0) {
      GetCommonData();
    }
    return () => {};
  }, [path]);
  return [getData, PagingUpdate];
};

export default UseGetCommon;
