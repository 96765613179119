import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Common_get_api } from "../../api/get_api";
import { authinterface, ProjectCardInter } from "../../interface/interface";
import { RootState } from "../../reducers";

import "./css/index.css";

type LocType = {
  state: string | undefined;
  pathname: string;
};
function TeamRoom() {
  const navigate = useNavigate();
  const { state: loc_prjt_id, pathname } = useLocation() as LocType;
  const BlindHeader =
    pathname.includes("write") ||
    pathname.includes("detail") ||
    pathname.includes("fix");
  const { usr_id, usr_typ } = useSelector<RootState, authinterface>(
    (state) => state.auth
  );
  const [data, setdata] = useState<ProjectCardInter[]>([]);
  const [nowPrj, setNowPrj] = useState<ProjectCardInter | undefined>(undefined);
  const [idx, setIdx] = useState<number>(0);

  useEffect(() => {
    nav_layout.forEach(({ type }, idx) => {
      if (type === pathname) {
        setIdx(idx);
      }
    });

    return () => {};
  }, [pathname]);

  useEffect(() => {
    if (usr_id && usr_typ) {
      Common_get_api(
        usr_typ === "READ"
          ? `/web/cstm/prjt?prjt_type_cd=prjt&leadr_usr_id=${usr_id}`
          : `/web/my/prjt?apprv_sts_cd=apprv_sts_select&prjt_type_cd=prjt&row_limit_cnt=1000`
      ).then(({ state, data }) => {
        if (state) {
          if (data.lst) {
            setdata(data.lst);
          } else {
            setdata(data);
          }
          if (loc_prjt_id) {
            data.lst.forEach((item: ProjectCardInter) => {
              if (item.prjt_id === loc_prjt_id) {
                setNowPrj(item);
              }
            });
          } else {
            if (data.lst) {
              setNowPrj(data.lst[0]);
            } else {
              setNowPrj(data[0]);
            }
          }
        }
      });
    }
    return () => {};
  }, [usr_id, usr_typ]);
  return (
    <main className="teamroom-section">
      {!BlindHeader ? (
        <>
          <div className="wrapper">
            <div
              className="my-teampl-project-list"
              style={{ gridTemplateColumns: `repeat(${data.length},141px)` }}
            >
              {data.map((item, idx) => {
                const { prjt_id, prjt_thumbnail, prjt_nm } = item;
                return (
                  <button
                    key={idx}
                    className={`teampl-project-card ${
                      nowPrj && nowPrj.prjt_id === prjt_id ? "active-card" : ""
                    }`}
                    onClick={() => {
                      setNowPrj(item);
                    }}
                  >
                    <div
                      className="back-img"
                      style={{ backgroundImage: `url(${prjt_thumbnail})` }}
                    />
                    <div className="title-wrapper">{prjt_nm}</div>
                  </button>
                );
              })}
            </div>
          </div>
          <div className="teampl-nav-section">
            <div className="nav-wrapper">
              {nav_layout.map(({ title, link, type }, idx) => {
                return (
                  <Link
                    to={link}
                    key={idx}
                    className={`${type === pathname ? "active-nav" : ""}`}
                  >
                    {title}
                  </Link>
                );
              })}
              <div
                className="nav-line"
                style={{
                  left: `${69 * idx}px`,
                }}
              />
            </div>
          </div>
        </>
      ) : undefined}
      <div
        className={`wrapper ${
          pathname.includes("detail") || pathname.includes("notice")
            ? "detail-wrapper"
            : ""
        }`}
      >
        <Outlet
          context={{
            data,
            navigate,
            nowPrj,
            isLeader: nowPrj && nowPrj.leadr_usr_id === usr_id,
            pathname,
            usr_id,
          }}
        />
      </div>
    </main>
  );
}

export default TeamRoom;

const nav_layout = [
  {
    title: "공지",
    link: "./notice",
    type: "/room/notice",
  },
  {
    title: "캘린더",
    link: "./calendar",
    type: "/room/calendar",
  },
  {
    title: "팀플룸",
    link: "./list",
    type: "/room/list",
  },
  {
    title: "팀원목록",
    link: "./member",
    type: "/room/member",
  },
];
