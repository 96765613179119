import React, { useCallback, useReducer } from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import { ProjectCardInter, RoomWriteInter } from "../interface/interface";
import { clientApi } from "../api/config";
import RoomWrite from "../pages/TeamRoom/sub/RoomWrite";

var info = {
  title: "",
  content: "",
  link: "",
  file: {
    name: "",
    url: "",
  },
};
const RoomWriteContainer = () => {
  const {
    navigate,
    pathname,
    nowPrj,
  }: {
    navigate: NavigateFunction;
    pathname: string;
    nowPrj: ProjectCardInter;
  } = useOutletContext();
  const category = pathname.split("/")[2];
  const [state, dispatch] = useReducer(reducer, info);
  const WriteRoom = useCallback(() => {
    if (state.title && state.content) {
      clientApi
        .post(`/web/cmnt/1/ctts`, {
          bbs_id: "1",
          prjt_id: nowPrj.prjt_id,
          ctts_tit: state.title,
          ctts_ctts: state.content,
          ctts_ctg_cd: category,
          file_link: state.file.url,
          link: state.link,
        })
        .then(({ data: { sts_fg } }) => {
          if (sts_fg) {
            navigate(-1);
          }
        });
    }
  }, [state, category, nowPrj, navigate]);

  return (
    <RoomWrite
      state={state}
      dispatch={dispatch}
      category={category}
      WriteRoom={WriteRoom}
    />
  );
};

export default RoomWriteContainer;
function reducer(
  state: RoomWriteInter,
  { payload, type, url }: { payload: string; type: string; url: string }
) {
  switch (type) {
    case "title":
      return { ...state, title: payload };
    case "content":
      return { ...state, content: payload };
    case "link":
      return { ...state, link: payload };
    case "file":
      return {
        ...state,
        file: {
          name: payload,
          url,
        },
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
