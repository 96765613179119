import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { clientApi } from "../../api/config";
import { Check_usr_email, Check_usr_id } from "../../api/user_api";
import CertifyInput from "../../components/CertifyInput/CertifyInput";
import LoginBtn from "../../components/LoginBtn/LoginBtn";
import LoginInput from "../../components/LoginInput/LoginInput";
import Policy from "../../components/Policy/Policy";
import { PolicyArr } from "../../lib/lib";
import { LoginUser, UserEmail } from "../../reducers/auth";
import { LoadingChange, ToastChange } from "../../reducers/config";
import "./css/index.css";
import { RootState } from "../../reducers";

var idreg = new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.{5,})");
const emailReg = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
export var passwordreg = new RegExp(
  "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkUid = useSelector<RootState, string>((state) => state.auth.usr_id);
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordconfirm, setPasswordconfirm] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [isCertify, setisCertify] = useState<boolean>(false);
  const [policy, setPolicy] = useState<Array<boolean>>([false, false, false]);
  const [errorType, setErrorType] = useState<string | undefined>(undefined);

  const __policy = useCallback(
    (index: number) => {
      let clone = policy.slice();
      clone[index] = !clone[index];
      setPolicy(clone);
    },
    [policy]
  );
  /** id 중복확인 */
  const IdCheck = useCallback(() => {
    return Check_usr_id(id).then((state) => {
      if (state) {
        return true;
      } else {
        dispatch(LoadingChange(false));
        setErrorType("usingId");
        return false;
      }
    });
  }, [id, dispatch]);

  const EmailCheck = useCallback(() => {
    if (email.length > 0) {
      if (emailReg.test(email)) {
        Check_usr_email(email).then(({ state }) => {
          if (state) {
            dispatch(LoadingChange(false));
            setErrorType("email");
            return false;
          } else {
            if (errorType === "email" || errorType === "email-reg") {
              setErrorType(undefined);
            }
            setisCertify(true);
            return true;
          }
        });
      } else {
        setErrorType("email-reg");
        return false;
      }
    } else {
      setErrorType("email-gap");
      return false;
    }
  }, [email, dispatch, errorType]);

  const __signup = useCallback(() => {
    dispatch(LoadingChange(true));
    if (id && password && passwordconfirm && email) {
      if (policy[0] && policy[1]) {
        dispatch(LoadingChange(true));
        IdCheck().then((result) => {
          if (result) {
            if (passwordreg.test(password) && password === passwordconfirm) {
              if (isCertify) {
                const body = {
                  login_id: id,
                  login_pwd: password,
                  usr_email: email,
                  login_type_cd: "normal",
                  agr_prv: policy[0],
                  agr_use: policy[1],
                  agr_adv: policy[2],
                };
                clientApi
                  .post("/auth/signup", body)
                  .then(({ data }) => {
                    if (data.sts_fg) {
                      clientApi
                        .post("/auth/signin", {
                          login_id: id,
                          login_pwd: password,
                        })
                        .then(({ data }) => {
                          if (data.sts_fg && data.succ_obj) {
                            const {
                              succ_obj: { accessToken, usr_id },
                            } = data;
                            const tokenDate = new Date();
                            tokenDate.setHours(tokenDate.getHours() + 24);
                            window.localStorage.setItem(
                              "accessToken",
                              JSON.stringify({
                                token: accessToken,
                                time: tokenDate,
                              })
                            );
                            dispatch(LoginUser(usr_id));
                            dispatch(UserEmail(email));
                            navigate("/signup/finish");
                            dispatch(LoadingChange(false));
                          } else {
                            dispatch(LoadingChange(false));
                            dispatch(
                              ToastChange({
                                active: true,
                                content: data.err_msg,
                              })
                            );
                          }
                        });
                    } else {
                      dispatch(LoadingChange(false));
                      dispatch(
                        ToastChange({ active: true, content: data.err_msg })
                      );
                    }
                  })
                  .catch(() => {
                    dispatch(
                      ToastChange({
                        active: true,
                        content: "네트워크가 불안정합니다",
                      })
                    );
                    dispatch(LoadingChange(false));
                  });
              } else {
                dispatch(LoadingChange(false));
                dispatch(
                  ToastChange({
                    active: true,
                    content: "이메일 중복확인을 진행해주세요",
                  })
                );
              }
            } else {
              dispatch(LoadingChange(false));
            }
          }
        });
      } else {
        dispatch(LoadingChange(false));
        setErrorType("policy");
      }
    } else {
      dispatch(LoadingChange(false));
      dispatch(
        ToastChange({ active: true, content: "정보를 모두 입력해주세요" })
      );
    }
  }, [
    id,
    password,
    email,
    passwordconfirm,
    policy,
    dispatch,
    IdCheck,
    navigate,
    isCertify,
  ]);

  const IdUpdate = useCallback(
    (e: string) => {
      if (errorType === "usingId") {
        setErrorType(undefined);
      }
      setId(e);
    },
    [errorType]
  );

  useEffect(() => {
    if (checkUid) {
      navigate("/");
      dispatch(
        ToastChange({
          active: true,
          content: "로그인 중에는 회원가입을 할수없습니다",
        })
      );
    }
    return () => {};
  }, [navigate, dispatch]);
  return (
    <main className="sign-up">
      <div className="wrapper">
        <div className="title">팀플 회원가입</div>
        <LoginInput
          type={"id"}
          title="아이디"
          placeholder={"숫자, 영문 조합 최소 5자"}
          setValue={IdUpdate}
          value={id}
          Check={undefined}
          errorType={
            id
              ? !idreg.test(id)
                ? "양식이 올바르지 않습니다"
                : errorType === "usingId"
                ? "사용중인 아이디입니다"
                : undefined
              : undefined
          }
        />
        <div className="password-wrapper">
          <LoginInput
            type={"password"}
            title="비밀번호"
            placeholder={"숫자, 영문, 특수문자 조합 최소 8자"}
            setValue={setPassword}
            value={password}
            Check={undefined}
            errorType={
              password && !passwordreg.test(password)
                ? "숫자, 영문, 특수문자 조합 최소 8자"
                : passwordconfirm && password !== passwordconfirm
                ? "비밀번호 확인이 일치하지 않습니다"
                : undefined
            }
          />
          <LoginInput
            Check={undefined}
            type={"password"}
            title={undefined}
            placeholder={"비밀번호 재입력"}
            setValue={setPasswordconfirm}
            errorType={undefined}
            value={passwordconfirm}
          />
        </div>
        <CertifyInput
          type={
            errorType === "email"
              ? "break"
              : errorType === "email-reg"
              ? "reg"
              : errorType === "email-gap"
              ? "gap"
              : isCertify
              ? "finish"
              : ""
          }
          isFocus={undefined}
          setIsFoucs={undefined}
          setValue={setemail}
          category="check"
          Certify={EmailCheck}
          setIsCertify={setisCertify}
          isCertify={isCertify}
          value={email}
        />
        <div className="policy-wrapper">
          <div className="title">
            약관동의
            <div
              className={`error ${
                errorType === "policy" ? "policy-error" : ""
              }`}
            >
              필수 약관에 대해서 동의해주세요
            </div>
          </div>
          <button
            onClick={() => {
              if (policy.every((v) => v)) {
                setPolicy([false, false, false]);
              } else {
                setPolicy([true, true, true]);
              }
            }}
            className={`all-policy ${
              policy.every((v) => v) ? "policy-active" : ""
            }`}
          >
            <div className="circle">
              <img src="/assets/login/check.svg" alt="" />
            </div>
            <div className="title">약관 전체 동의</div>
          </button>
          <div className="policy-detail">
            {PolicyArr.map((item, idx) => {
              return (
                <Policy
                  key={idx}
                  data={item}
                  active={policy[idx]}
                  __click={__policy}
                  index={idx}
                />
              );
            })}
          </div>
          <LoginBtn type="login" title="회원가입" __submit={__signup} />
        </div>
      </div>
      <Outlet />
    </main>
  );
}

export default Signup;
